import { memo, useMemo } from 'react';
import classNames from 'classnames';

import CloudinaryImage from 'components/common/CloudinaryImage';
import { Link } from 'components/common/Link';
import Button from 'components/design-system/Button';
import { useViewport } from 'context/viewport';
import { StyledComponent } from 'types';

import AuthenticateMobileBanner from './AuthenticateMobileBanner';

const bannerStyle = {
  height: '800px'
};

const btnStyle = { background: 'rgba(255, 252, 249, 0.07)' };

const description =
  'Every watch is sent to our in-house experts before it gets to you to ensure you can shop with 100% confidence. No fakes, ever.';

const AuthenticateBanner: React.FC<StyledComponent> = ({ className }) => {
  const { isTabletOrMobile, isMobile } = useViewport();

  const styles = useMemo(
    () => ({
      banner: isTabletOrMobile ? { ...bannerStyle, height: '500px' } : bannerStyle,
      button: isTabletOrMobile ? btnStyle : { ...btnStyle, marginTop: '100px' }
    }),
    [isTabletOrMobile]
  );

  if (isMobile) {
    return <AuthenticateMobileBanner />;
  }

  return (
    <div style={{ background: '#2B2218' }} className={classNames('w-100', className)}>
      <div className='position-relative' style={styles.banner}>
        <CloudinaryImage
          fill
          alt='Silver Watch'
          src='silver-watch-back.png'
          sizes='50vw'
          style={{ objectFit: 'contain', objectPosition: 'right center' }}
        />
        <div
          className={'position-relative d-flex align-items-center h-100 text-white riforma-regular mx-auto px-4'}
          style={{ maxWidth: '940px' }}
        >
          <div className='w-50'>
            <div className='fs-28px line-height-36px mb-4 mb-lg-5 white-space-break me-lg-7'>
              <span>{'Your trust is'}</span>
              <br />
              <span className='bradford-regular-italic'>{'everything'}</span> to us
            </div>
            <div className='opacity-60 fs-16px line-height-24px mb-5 me-lg-7'>{description}</div>
            <Link href='https://www.getbezel.com/authentication' target='_blank'>
              <Button
                variant='custom'
                style={styles.button}
                className='border-0 py-3 px-5 text-white w-75 shadow-on-hover'
              >
                Learn more
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(AuthenticateBanner);
