import { FC } from 'react';

import { useViewport } from 'context/viewport';

import { AuctionListingCountdownDesktop, AuctionListingCountdownMobile } from './AuctionListingItemCountdown';

import { formatAuctionDateNoTime } from '../Drops/helpers';

export const AuctionListingItemInfoTestIds = {
  mobile: 'action-listing-item-info-mobile',
  desktop: 'action-listing-item-info-desktop'
};

const ResultText: FC<{ endDate: string }> = ({ endDate }) => (
  <span>
    <span className='text-light40 me-1'>ENDED</span>{' '}
    <span className='text-turquoise'>{formatAuctionDateNoTime(endDate)}</span>
  </span>
);

export type AuctionListingItemInfoProps = {
  name: string;
  isResult?: boolean;
  endDate: string;
  bid: string | null;
};

export const AuctionListingItemInfo: FC<AuctionListingItemInfoProps> = ({ name, isResult, endDate, bid }) => {
  const { isMobile } = useViewport();
  const isEndedWithNoBid = isResult && bid === null;

  return (
    <div
      className='fs-12px fs-sm-14px p-2 p-sm-3 flex-1 d-flex flex-column justify-content-between'
      style={{
        backgroundColor: 'rgba(255, 252, 249, 0.08)'
      }}
    >
      <div className='text-light px-1 clamp-lines-3 line-height-20px' style={{ height: '60px' }}>
        {name}
      </div>
      {isMobile ? (
        <div data-testid={AuctionListingItemInfoTestIds.mobile}>
          <div className='py-2 mt-3 bg-grey border-radius-12'>
            {isResult ? (
              <div className='ms-4'>
                <ResultText endDate={endDate} />
              </div>
            ) : (
              <AuctionListingCountdownMobile endDate={endDate} />
            )}
          </div>
          <div className='d-flex ms-4 mt-1 mb-2 text-light40'>
            <div className='w-40'>BID</div>
            <div className='riforma-medium ps-1 text-turquoise w-60'>{bid}</div>
          </div>
        </div>
      ) : (
        <div
          data-testid={AuctionListingItemInfoTestIds.desktop}
          className='d-flex py-2 px-3 justify-content-between bg-grey border-radius-17 mt-3'
        >
          {isResult ? <ResultText endDate={endDate} /> : <AuctionListingCountdownDesktop endDate={endDate} />}
          <div className='text-light40'>
            {isEndedWithNoBid ? null : (
              <>
                BID<span className='ps-1 text-turquoise'>{bid}</span>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
