import classNames from 'classnames';

import CloudinaryImage from 'components/common/CloudinaryImage';
import { Link } from 'components/common/Link';
import { useViewport } from 'context/viewport';
import { Article } from 'types';

export const ArticleCardTestIds = {
  container: 'article-card-container'
};

export interface ArticleCardProps {
  article: Article;
}

const ArticleCard: React.FC<ArticleCardProps> = ({ article }) => {
  const { isMobile } = useViewport();
  const descriptionStyles = {
    height: '-webkit-fill-available',
    top: isMobile ? '50%' : '60%'
  };

  return (
    <Link href={article.url} target='_blank' data-testid={ArticleCardTestIds.container}>
      <div
        className={classNames(
          'rounded-2 position-relative cursor-pointer overflow-hidden text-light h-100 border d-flex flex-column justify-content-between',
          { 'shadow-on-hover': !isMobile }
        )}
      >
        <div
          className={classNames('overflow-hidden position-absolute w-100 top-0', {
            'h-60': !isMobile,
            'h-50': isMobile
          })}
        >
          <CloudinaryImage
            fill
            alt='Article image'
            sizes='(max-width: 768px) 100vw, 33vw'
            src={article.bannerImage.optimizedUrl}
            style={{ objectFit: 'cover', objectPosition: 'center center' }}
          />
        </div>
        <div
          className='text-light riforma-regular bg-light flex-column d-flex w-100 justify-content-between p-3 position-absolute'
          style={descriptionStyles}
        >
          <div>
            <div className='text-secondary fs-12px text-uppercase mb-1'>{article.publisher}</div>
            <div
              className={classNames(
                'fs-14px line-height-18px fs-sm-16px line-height-sm-24px text-primary mb-2',
                isMobile ? 'clamp-lines-3' : 'clamp-lines-2'
              )}
            >
              {article.title}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ArticleCard;
