import classNames from 'classnames';
import useSWR from 'swr';

import { clientApiGet } from 'lib/api_helper';
import { apiUrls } from 'lib/api_urls';
import { Article, Brand, Collection, Listing, Model, SECTIONS_TYPE } from 'types';

import { ArticleCarousel } from './ArticleCarousel';
import { AuctionsCarousel } from './AuctionsCarousel';
import { BrandCarousel } from './BrandCarousel';
import CollectionsCarousel from './CollectionsCarousel';

import ListingCarousel from '../common/ListingCarousel';
import ModelCarousel from '../common/ModelCarousel';

export type HomeFeedItem = Brand & Model & Listing & Collection<Model | Listing | Brand> & Article;

export type ListItemsData = { items: HomeFeedItem[] };

export type HomeFeedComponent =
  | typeof BrandCarousel
  | typeof ModelCarousel
  | typeof ArticleCarousel
  | typeof ModelCarousel
  | typeof ListingCarousel
  | typeof CollectionsCarousel
  | typeof AuctionsCarousel;

export const homeFeedSectionMapsComponent: Record<string, { Component: HomeFeedComponent; query?: string }> = {
  [SECTIONS_TYPE.BrandCluster]: { Component: BrandCarousel, query: 'items' },
  [SECTIONS_TYPE.ModelCluster]: { Component: ModelCarousel, query: 'items' },
  [SECTIONS_TYPE.ArticleCluster]: { Component: ArticleCarousel, query: 'items.bannerImage' },
  [SECTIONS_TYPE.ListingCluster]: { Component: ListingCarousel, query: 'items.model' },
  [SECTIONS_TYPE.CollectionCluster]: { Component: CollectionsCarousel, query: 'items' },
  [SECTIONS_TYPE.AuctionCluster]: { Component: AuctionsCarousel, query: 'items.model' }
};

export interface MapCarouselItemsComponentProps {
  type: SECTIONS_TYPE;
  objectId: number;
  title: string;
  spacerClassname: string;
  serverItems?: HomeFeedItem[];
}

const MapCarouselItemsComponent: React.FC<MapCarouselItemsComponentProps> = ({
  type,
  objectId,
  title,
  spacerClassname,
  serverItems
}) => {
  const { Component: CarouselComponent, query } = homeFeedSectionMapsComponent[type] || { Component: null };

  const requestUrl = apiUrls.getList(objectId, query ? { 'expand[]': query } : undefined);

  const { data } = useSWR<ListItemsData>(serverItems ? null : requestUrl, clientApiGet);

  if (!CarouselComponent) {
    return null;
  }

  return (
    <CarouselComponent
      title={title}
      className={classNames('main-content py-0', spacerClassname, { 'mw-100': type === SECTIONS_TYPE.AuctionCluster })}
      items={serverItems || data?.items || []}
      loading={serverItems ? false : !data}
    />
  );
};

export default MapCarouselItemsComponent;
