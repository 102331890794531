import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { FC, useState } from 'react';
import { FaAngleLeft } from '@react-icons/all-files/fa6/FaAngleLeft';
import { FaAngleRight } from '@react-icons/all-files/fa6/FaAngleRight';
import classNames from 'classnames';
import SwiperCore, { Lazy } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';

import { AuctionListingItem } from 'components/auctions/Listings/AuctionListingItem';
import ContentLoader from 'components/design-system/ContentLoader';
import { COLORS } from 'consts';
import { useViewport } from 'context/viewport';
import styles from 'styles/Auctions.module.scss';
import { Listing, StyledComponent } from 'types';

interface AuctionsCarouselProps extends StyledComponent {
  items: Listing[];
  title: React.ReactNode;
  loading?: boolean;
}

const auctionsCarouselSlidesPerView: Record<string, number> = {
  desktop: 3,
  tableOrMobile: 2
};

export const AuctionsCarousel: FC<AuctionsCarouselProps> = ({ items, title, className, loading }) => {
  const { isTabletOrMobile } = useViewport();

  const [swiper, setSwiper] = useState<SwiperType>();

  const slidesPerView = isTabletOrMobile
    ? auctionsCarouselSlidesPerView.tableOrMobile
    : auctionsCarouselSlidesPerView.desktop;

  SwiperCore.use([Lazy]);

  return (
    <div className={classNames('w-100 py-3 mb-4 bg-darkTheme', className)}>
      <div className='container main-content py-3 px-3 riforma-medium position-relative'>
        <div className='mb-1 text-light fs-16px fs-sm-18px mb-2'>{title}</div>
        {!isTabletOrMobile && (
          <div
            className={styles.sliderHomeArrow}
            style={{ left: -50, color: COLORS.primaryLight }}
            role='button'
            onClick={() => swiper?.slidePrev()}
          >
            <FaAngleLeft />
          </div>
        )}
        <Swiper
          loop
          lazy
          spaceBetween={10}
          loopedSlides={slidesPerView}
          slidesPerView={slidesPerView}
          onSwiper={setSwiper}
          initialSlide={slidesPerView}
          allowTouchMove={isTabletOrMobile}
        >
          {items?.map((item, idx) => (
            <SwiperSlide key={`slide-${idx}-${item.id}`}>
              {loading ? (
                <ContentLoader borderRadius={20} height={isTabletOrMobile ? '310px' : '420px'} />
              ) : (
                <AuctionListingItem fullWidth listing={item} />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        {!isTabletOrMobile && (
          <div
            className={styles.sliderHomeArrow}
            style={{ right: -50, color: COLORS.primaryLight }}
            role='button'
            onClick={() => swiper?.slideNext()}
          >
            <FaAngleRight />
          </div>
        )}
      </div>
    </div>
  );
};
