import { FC } from 'react';
import Link from 'next/link';
import classNames from 'classnames';

import CloudinaryImage from 'components/common/CloudinaryImage';
import { useViewport } from 'context/viewport';
import { Listing } from 'types';
import { ONE_DAY } from 'utils/date';

import { AuctionListingItemInfo } from './AuctionListingItemInfo';
import { AuctionListingTag } from './AuctionListingTag';

import { getAuctionDataFromListing } from '../helpers';

export const AuctionListingItemTestIds = {
  container: 'action-listing-item-container'
};

export type AuctionListingItemProps = {
  tag?: 'ending' | 'justAdded' | 'ended';
  fullWidth?: boolean;
  listing: Listing;
  isResult?: boolean;
};

export const AuctionListingItem: FC<AuctionListingItemProps> = ({ tag, fullWidth, listing, isResult = false }) => {
  const { isMobile } = useViewport();

  const { bid, href, imageUrl, name } = getAuctionDataFromListing(listing);

  if (!listing.auctionInfo) {
    return null;
  }

  const startedWithinLastDay =
    listing.auctionInfo.live && Date.parse(listing.auctionInfo.startDate) > Date.now() - ONE_DAY;
  const endsWithinNextDay = listing.auctionInfo.live && Date.parse(listing.auctionInfo.endDate) < Date.now() + ONE_DAY;

  return (
    <Link
      data-testid={AuctionListingItemTestIds.container}
      href={href}
      className={classNames('rounded-2 d-block position-relative overflow-hidden riforma-regular h-100', {
        'w-32': !isMobile && !fullWidth,
        'w-48': isMobile && !fullWidth,
        'my-2': !fullWidth
      })}
    >
      <div className='position-relative bg-grey' style={{ aspectRatio: 1 }}>
        {tag && <AuctionListingTag type={tag} />}
        {!tag && startedWithinLastDay && <AuctionListingTag type='justAdded' />}
        {!tag && endsWithinNextDay && <AuctionListingTag type='ending' />}
        <CloudinaryImage
          fill
          sizes={isMobile ? '50vw' : '35vw'}
          src={imageUrl}
          alt={name}
          style={{ objectFit: 'cover', objectPosition: 'bottom' }}
        />
      </div>
      <AuctionListingItemInfo bid={bid} endDate={listing.auctionInfo.endDate} name={name} isResult={isResult} />
    </Link>
  );
};
