import { memo, useMemo } from 'react';
import classNames from 'classnames';
import { useSwiperSlide } from 'swiper/react';

import CloudinaryImage from 'components/common/CloudinaryImage';
import { Link } from 'components/common/Link';
import { useViewport } from 'context/viewport';
import { pageRoutes } from 'lib/routes';
import { Collection, StyledComponent } from 'types';
import { getCustomMarkupFormattedText } from 'utils/text-helpers/getCustomMarkupFormattedText';

interface SimpleCardProps extends StyledComponent {
  collection: Collection<unknown>;
}

const titleContainerStyle = {
  top: '5%',
  color: 'rgba(52, 48, 45, 1)'
};

const bgContainerStyle = {
  width: '100%',
  bottom: '0%'
};

const SimpleCard: React.FC<SimpleCardProps> = ({ collection, className, style }) => {
  const formattedTitle = useMemo(
    () => getCustomMarkupFormattedText(collection.formattedTitle),
    [collection.formattedTitle]
  );

  const foreGroundImage = collection.foregroundImages['PORTRAIT_CARD'];

  const { isMobile } = useViewport();
  const swiperSlide = useSwiperSlide();

  const showCard = swiperSlide == null || swiperSlide.isVisible || isMobile;

  return (
    <Link passHref href={pageRoutes.fullCollectionUrl(collection.name, collection.id)}>
      <div
        className={classNames(
          'rounded-2 overflow-hidden text-light position-relative',
          className,
          showCard ? 'opacity-1' : 'opacity-0'
        )}
        style={style}
      >
        <CloudinaryImage
          fill
          alt={`${collection.name} Collection Background`}
          src={collection.backgroundImages['PORTRAIT_CARD'].optimizedUrl}
          style={{ objectFit: 'cover' }}
          sizes='50vw'
        />
        <div className='position-absolute absolute-centered-x' style={bgContainerStyle}>
          {foreGroundImage && (
            <CloudinaryImage
              responsive
              alt={`${collection.name} Listing Simple Card Image`}
              sizes='50vw'
              src={collection.foregroundImages['PORTRAIT_CARD'].optimizedUrl}
            />
          )}
        </div>
        <div
          className='position-absolute mt-2 text-light riforma-regular absolute-centered-x text-center'
          style={titleContainerStyle}
        >
          <div className='opacity-60 text-uppercase mb-2 opacity-60 fs-12px'>Collection</div>
          <div className='fs-24px line-height-32px'>{formattedTitle}</div>
        </div>
      </div>
    </Link>
  );
};

export default memo(SimpleCard);
