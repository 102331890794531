import { IconBaseProps, IconType } from '@react-icons/all-files/lib';
import classNames from 'classnames';

import Button, { ButtonProps } from 'components/design-system/Button/Button';

interface DefaultBannerButtonProps extends Omit<ButtonProps, 'content'> {
  startIcon?: IconType;
  endIcon?: IconType;
  iconProps?: IconBaseProps;
  content?: React.ReactNode;
}

export const DefaultBannerButton: React.FC<DefaultBannerButtonProps> = ({
  children,
  startIcon,
  endIcon,
  iconProps,
  className,
  textClassname,
  content,
  ...rest
}) => {
  const StartIcon = startIcon ? startIcon : undefined;
  const EndIcon = endIcon ? endIcon : undefined;

  return (
    <Button
      variant='custom'
      className={classNames(
        'rounded-2 border fs-12px fs-md-16px border-secondary py-3 px-4 d-flex mt-4 mt-lg-5 w-40 w-sm-25 d-flex justify-content-between align-items-center shadow-on-hover',
        className
      )}
      textClassname={classNames('d-flex justify-content-between align-items-center w-100', textClassname)}
      role='button'
      {...rest}
    >
      {StartIcon && <StartIcon {...iconProps} />}
      {content}
      {children}
      {EndIcon && <EndIcon {...iconProps} />}
    </Button>
  );
};
