import { featuredPressItems } from 'components/common/promotional/FeaturedPress/consts';
import FeaturedPressCarousel from 'components/common/promotional/FeaturedPress/FeaturedPressCarousel';

export const logoRibbonStyles = { backgroundColor: 'rgba(38, 37, 36, 1)' };
const logosStyle = { minWidth: '750px' };

export const FeaturedPressTestIds = {
  container: 'featured-press-container'
};

export const FeaturedPress = () => (
  <div
    data-testid={FeaturedPressTestIds.container}
    className='py-2 py-md-3 overflow-hidden hide_scroll_bar'
    style={logoRibbonStyles}
  >
    <div style={logosStyle}>
      <FeaturedPressCarousel className='w-100' items={featuredPressItems} />
    </div>
  </div>
);
