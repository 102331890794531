import { CSSProperties, memo } from 'react';

import { cloudinaryLoader } from 'components/common/CloudinaryImage';
import { StyledComponent } from 'types';

const bannerContainerStyle: CSSProperties = {
  backgroundColor: '#2B2218'
};

const bannerStyle: CSSProperties = {
  backgroundImage: `url(${cloudinaryLoader({
    src: '\\silver-watch-back.png',
    width: 3640,
    quality: 100
  })})`,
  height: 300,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'auto 100%',
  backgroundPosition: 'right'
};

interface AuthenticateMobileBannerProps extends StyledComponent {}

const AuthenticateMobileBanner: React.FC<AuthenticateMobileBannerProps> = ({ className, style }) => (
  <div className={className} style={{ ...bannerContainerStyle, ...style }}>
    <div className='riforma-regular p-4 position-relative' style={bannerStyle}>
      <div
        className='w-50 text-white d-flex justify-content-center h-100 white-space-break flex-column fs-24px line-height-28px'
        style={{ zIndex: 2, position: 'relative', top: '-20px' }}
      >
        <span>{'Your trust is'}</span> <span className='bradford-regular-italic'>{'everything'}</span>to us
      </div>
    </div>
  </div>
);

export default memo(AuthenticateMobileBanner);
