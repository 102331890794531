import { ImageLoaderProps } from 'next/image';
import classNames from 'classnames';

import CloudinaryImage from 'components/common/CloudinaryImage';
import { useViewport } from 'context/viewport';
import styles from 'styles/Banner.module.scss';

import { DefaultBannerGraphic } from './types';

interface DefaultBannerForegroundProps extends DefaultBannerGraphic {
  image?: Partial<ImageLoaderProps> & { overrideTransformations?: string[] };
  priority?: boolean;
}

export const DefaultBannerForeground: React.FC<DefaultBannerForegroundProps> = ({
  image,
  color,
  orientation,
  className,
  children,
  style,
  priority = false
}) => {
  const { isMobile } = useViewport();

  return (
    <div className={classNames('w-100 h-100 position-relative', styles['banner-item-foreground'], className)}>
      {image && image.src && (
        <CloudinaryImage
          fill
          src={image.src}
          alt='Banner foreground'
          sizes={isMobile ? '100vw' : '50vw'}
          style={{
            objectFit: 'contain',
            objectPosition: orientation,
            backgroundColor: color,
            ...style
          }}
          priority={priority}
        />
      )}
      {children}
    </div>
  );
};
