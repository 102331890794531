import {
  NextSeo,
  NextSeoProps,
  OrganizationJsonLd,
  OrganizationJsonLdProps,
  SiteLinksSearchBoxJsonLd,
  SiteLinksSearchBoxJsonLdProps
} from 'next-seo';

import { useBranding } from 'context/branding';

const title = 'Pre-Owned Luxury Watches | Authenticated In-House | Bezel';
const siteUrl = process.env.NEXT_PUBLIC_SITE_BASE_URL || 'https://shop.getbezel.com';
const description =
  'Bezel is the top marketplace in the world for authenticated luxury watches. Get free in-house authentication and fully-insured overnight shipping with every purchase.';

const orgJsonLd: OrganizationJsonLdProps = {
  type: 'Corporation',
  name: 'Bezel',
  legalName: 'Bezel Inc.',
  alternateName: 'Get Bezel',
  description,
  address: {
    streetAddress: '1653 7th Street #1606',
    addressLocality: 'Santa Monica',
    addressRegion: 'California',
    postalCode: '90406',
    addressCountry: 'United States'
  },
  sameAs: [
    'https://www.linkedin.com/company/getbezel/',
    'https://www.youtube.com/@getbezel',
    'https://www.instagram.com/getbezel/',
    'https://www.tiktok.com/@getbezel',
    'https://twitter.com/getbezel'
  ],
  contactPoint: [
    {
      telephone: '+1-888-483-3606',
      contactType: 'Customer Service',
      email: 'team@getbezel.com',
      contactOption: 'TollFree',
      availableLanguage: 'English',
      areaServed: 'US'
    }
  ],
  url: siteUrl
};

const searchBoxJsonLd: SiteLinksSearchBoxJsonLdProps = {
  url: siteUrl,
  name: 'Bezel',
  potentialActions: [
    {
      target: `${siteUrl}/explore?searchQuery`,
      queryInput: 'search_term_string'
    }
  ]
};

const bezelLogo = 'https://getbezel.mo.cloudinary.net/static/bezel-nav-logo.png';

const HomeHead = () => {
  const { sellerSiteMetadata, useSellerBranding } = useBranding();

  const logo = sellerSiteMetadata?.faviconImage?.url || bezelLogo;

  const config: NextSeoProps = {
    title,
    description,
    openGraph: {
      title,
      description,
      images: [
        {
          url: logo,
          alt: useSellerBranding ? `${sellerSiteMetadata?.businessName} Logo` : 'Bezel Site Global Image',
          type: 'image/png'
        }
      ]
    }
  };

  return (
    <>
      <NextSeo {...config} />
      <OrganizationJsonLd {...orgJsonLd} logo={logo} />
      <SiteLinksSearchBoxJsonLd {...searchBoxJsonLd} />
    </>
  );
};

export default HomeHead;
