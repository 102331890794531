import { useMemo } from 'react';
import classNames from 'classnames';
import { SwiperProps, SwiperSlide } from 'swiper/react';

import { ArrowClassname, Carousel } from 'components/common/Carousel';
import ContentLoader from 'components/design-system/ContentLoader';
import { useViewport } from 'context/viewport';
import styles from 'styles/Collections.module.scss';
import { Collection, CollectionStyle, StyledComponent } from 'types';

import FloatingCard from './FloatingCard';
import SimpleCard from './SimpleCard';

type LoadingItemType = { id: number; loading: true };

interface CollectionsCarouselProps extends StyledComponent {
  items: (Collection<unknown> | LoadingItemType)[];
  loading: boolean;
}

const cardsMap = {
  [CollectionStyle.Simple]: SimpleCard,
  [CollectionStyle.FloatingImage]: FloatingCard
};

const settings = {
  name: 'Collections'
};

const placeholders = Array.from(new Array(10)).map((_, i) => ({ id: i }));
const HEIGHT_MOBILE = '320px';
const HEIGHT_DESKTOP = '380px';
const arrowClassname: ArrowClassname = {
  left: styles['bg-transparent'],
  right: styles['bg-transparent']
};

const getComponent = (
  item: Collection<unknown>,
  componentStyle: {
    height: string;
  }
) => {
  const Component = cardsMap[item.style];

  return (
    <Component
      collection={item}
      className={classNames('cursor-pointer', styles['collections-carousel-item'])}
      style={componentStyle}
    />
  );
};

const CollectionsCarousel: React.FC<CollectionsCarouselProps> = ({ items, className, loading }) => {
  const { isMobileSmall } = useViewport();

  const collections = items?.filter(item => loading || !!(item as Collection<unknown>).list) || placeholders;

  const componentStyle = useMemo(() => ({ height: isMobileSmall ? HEIGHT_MOBILE : HEIGHT_DESKTOP }), [isMobileSmall]);

  const swiperProps: SwiperProps = {
    className: classNames('px-4 pt-3 w-100', styles['collections-carousel']),
    watchSlidesProgress: !isMobileSmall
  };

  return (
    <Carousel
      virtual
      title={settings.name}
      className={className}
      slidesPerView={isMobileSmall ? 1.2 : 3}
      swiperProps={swiperProps}
      showArrowGradient={false}
      arrowClassname={loading ? undefined : arrowClassname}
    >
      {() =>
        collections.map((collectionItem, idx) => (
          <SwiperSlide key={`collection-card-${collectionItem.id}-${idx}`} virtualIndex={idx}>
            {loading ? (
              <ContentLoader borderRadius={20} height={isMobileSmall ? HEIGHT_MOBILE : HEIGHT_DESKTOP} />
            ) : (
              getComponent(collectionItem as Collection<unknown>, componentStyle)
            )}
          </SwiperSlide>
        ))
      }
    </Carousel>
  );
};

export default CollectionsCarousel;
